import React from "react";
import { T } from "react-targem";

import ButtonLink, { ButtonLinkProps } from "@src/components/ButtonLink";
import { appLink } from "@src/utils/content/links";

export type SignUpButtonProps = Omit<ButtonLinkProps, "classes" | "to">;

class SignUpButtonBase extends React.PureComponent<
  SignUpButtonProps
> {
  public render() {
    const { children, ...rest } = this.props;
    return (
      <ButtonLink
        to={`${appLink}/register`}
        variant="contained"
        color="secondary"
        external
        {...rest}>
        {children || <T message="Sign Up" />}
      </ButtonLink>
    );
  }
}

export const SignUpButton = SignUpButtonBase;
