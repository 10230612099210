export const magazineLinks: { [key: string]: string } = {
  en: "https://yosepov.github.io/trucknetmagazine/",
  he: "https://yosepov.github.io/trucknetmagazineil",
  it: "https://yosepov.github.io/trucknetmagazineit/",
  ro: "https://yosepov.github.io/trucknetmagazinero/",
  fr: "https://yosepov.github.io/trucknetmagazinefr/",
};

export const getMagazineLink = (locale: string): string => {
  if (Object.keys(magazineLinks).includes(locale)) {
    return magazineLinks[locale];
  }
  return magazineLinks.en;
};
