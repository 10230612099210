import { Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { ContactUsPageProps } from "@src/templates/ContactUsPage";

//tslint:disable: no-relative-imports
import ContactInfoBlock from "../ContactInfoBlock";
import DaysRange from "../DaysRange";
import TimeRange from "../TimeRange";
//tslint:enable: no-relative-imports
import { styles } from "./RomaniaContactInfo.styles";

export type RomaniaContactInfoProps = WithPageData<ContactUsPageProps> &
  WithStyles<typeof styles> & {
    className?: string;
  };

class RomaniaContactInfo extends React.PureComponent<RomaniaContactInfoProps> {
  public render() {
    const { classes, pageData, className } = this.props;

    return (
      <ContactInfoBlock
        className={className}
        image={pageData.romania}
        title={
          <span className={classes.title}>
            <T message="Romania" />
          </span>
        }
        addressLink="https://goo.gl/maps/6GzCQ2yPLkiqzFEy8"
        address="Str Dumbravei nr.11, etaj 1, Pitesti, Jud Argeș"
        phone="+40348880020"
        email="ro@trucknet.io"
        timeSchedule={
          <Typography className={classes.workingHours} variant="h5">
            <DaysRange from="Mon" to="Fri" />
            <TimeRange from="8:30" to="17:00" />
          </Typography>
        }
      />
    );
  }
}

export default withPageData(withStyles(styles)(RomaniaContactInfo));
