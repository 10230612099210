import { Link as MuiLink, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import LocalizedLink, {
  LocalizedLinkProps,
} from "@src/components/LocalizedLink";

import { styles } from "./Link.styles";

export type LinkProps = Omit<LocalizedLinkProps, "innerRef"> & {
  active?: boolean;
  external?: boolean;
  target?: "_self";
};

export class Link extends React.PureComponent<
  WithStyles<typeof styles> & LinkProps
> {
  public render() {
    const {
      className,
      classes,
      to,
      active,
      external,
      target,
      ...rest
    } = this.props;

    if (external) {
      return (
        // @ts-ignore
        <MuiLink
          className={cn({ active }, className)}
          href={to}
          target={target || "_blank"}
          {...rest}
        />
      );
    }

    return (
      <LocalizedLink
        className={cn(classes.link, { [classes.active]: active }, className)}
        to={this.getTo()}
        {...rest}
      />
    );
  }

  // Fixes: link is not active when pathname=="/about/" && to=="/about"
  protected getTo(): string {
    const { to } = this.props;
    if (typeof window === "undefined") {
      return to;
    }
    if (window.location.pathname.endsWith("/") && !to.endsWith("/")) {
      return `${to}/`;
    }
    if (to.endsWith("/") && !window.location.pathname.endsWith("/")) {
      return to.slice(0, -1);
    }
    return to;
  }
}

export default withStyles(styles)(Link);
