import { ListItem, withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import NavLink from "@src/components/Header/Navigation/NavLink";
import { LinkProps } from "@src/components/Link";

import { styles } from "./NavLinkItem.styles";

export type Props = WithStyles<typeof styles> &
  LinkProps & {
    to: string;
    children: React.ReactNode;
  };

class NavLinkItem extends React.PureComponent<Props> {
  public render() {
    const { to, children, classes, ...rest } = this.props;
    return (
      <NavLink {...rest} to={to}>
        <ListItem button className={classes.listItem}>
          {children}
        </ListItem>
      </NavLink>
    );
  }
}

export default withStyles(styles)(NavLinkItem);
