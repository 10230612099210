import { withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import Link from "@src/components/Link";
import { LinkedInLink, TwitterLink } from "@src/components/Social";
import WhenBlogAvailable from "@src/components/WhenBlogAvailable";
import WhenCareersAvailable from "@src/components/WhenCareersAvailable";

import Column from "./Column";
import { styles } from "./Desktop.styles";

export type DesktopNavigationProps = WithStyles<typeof styles> & {
  className?: string;
};

class DesktopNavigation extends React.PureComponent<DesktopNavigationProps> {
  public render() {
    const { classes, className } = this.props;
    return (
      <nav className={cn(classes.container, className)}>
        <Column title={<T message="Company" />}>
          <Link to="/about">
            <T message="About Us" />
          </Link>
          <WhenCareersAvailable>
            <Link to="/careers">
              <T message="Careers" />
            </Link>
          </WhenCareersAvailable>
          <Link to="/quality-policy">
            <T message="Quality Policy" />
          </Link>
        </Column>

        <Column title={<T message="Resources" />}>
          <WhenBlogAvailable>
            <Link to="/blog">
              <T message="Blog" />
            </Link>
          </WhenBlogAvailable>
          <Link to="/press">
            <T message="Press" />
          </Link>
          <WhenCareersAvailable>
            <Link to="/photos">
              <T message="Photos" />
            </Link>
          </WhenCareersAvailable>
          <Link to="/videos">
            <T message="Videos" />
          </Link>
        </Column>

        <Column title={<T message="Stay in touch" />}>
          <Link to="/contact">
            <T message="Contact Us" />
          </Link>
          <Link to="/support">
            <T message="Support" />
          </Link>
        </Column>

        <Column title={<T message="Follow us" />} simple>
          <TwitterLink className={classes.firstSocialLink} />
          <LinkedInLink />
        </Column>
      </nav>
    );
  }
}

export default withStyles(styles)(DesktopNavigation);
