import { Container, Divider, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import IsoLogo from "@src/components/IsoLogo";
import Link from "@src/components/Link";
import { LinkedInLink, TwitterLink } from "@src/components/Social";
import WhenBlogAvailable from "@src/components/WhenBlogAvailable";
import WhenCareersAvailable from "@src/components/WhenCareersAvailable";

import ExpansionItem from "./ExpansionItem";
import LinkItem from "./LinkItem";
import { styles } from "./Mobile.styles";

export type MobileNavigationProps = {
  className?: string;
};

class MobileNavigation extends React.PureComponent<
  WithStyles<typeof styles> & MobileNavigationProps
> {
  public render() {
    const { classes, className } = this.props;
    return (
      <nav className={cn(classes.container, className)}>
        <ExpansionItem title={<T message="Company" />}>
          <Link to="/about">
            <T message="About Us" />
          </Link>
          <WhenCareersAvailable>
            <Link to="/careers">
              <T message="Careers" />
            </Link>
          </WhenCareersAvailable>
          <Link to="/quality-policy">
            <T message="Quality Policy" />
          </Link>
        </ExpansionItem>

        <Divider />

        <ExpansionItem title={<T message="Resources" />}>
          <WhenBlogAvailable>
            <Link to="/blog">
              <T message="Blog" />
            </Link>
          </WhenBlogAvailable>
          <Link to="/press">
            <T message="Press" />
          </Link>
          <WhenCareersAvailable>
            <Link to="/photos">
              <T message="Photos" />
            </Link>
          </WhenCareersAvailable>
          <Link to="/videos">
            <T message="Videos" />
          </Link>
        </ExpansionItem>

        <Divider />

        <ExpansionItem title={<T message="Stay in touch" />}>
          <Link to="/contact">
            <T message="Contact Us" />
          </Link>
          <Link to="/support">
            <T message="Support" />
          </Link>
        </ExpansionItem>

        <Divider />

        <LinkItem to="/terms-and-conditions">
          <T message="Terms and Conditions" />
        </LinkItem>

        <Divider />

        <LinkItem to="/privacy-policy">
          <T message="Privacy Policy" />
        </LinkItem>

        <Divider />

        <Container className={classes.linksContainer}>
          <IsoLogo />
          <div className={classes.socialLinks}>
            <TwitterLink />
            <LinkedInLink />
          </div>
        </Container>
      </nav>
    );
  }
}

export default withStyles(styles)(MobileNavigation);
