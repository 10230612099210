import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      padding: theme.spacing(1.5, 3),
      color: theme.palette.primary.main,
      whiteSpace: "nowrap",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  });
