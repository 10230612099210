import React from "react";
import { Utils, useLocale } from "react-targem";

import Link, { LinkProps } from "@src/components/Link";
import { APP_SUPPORTED_LOCALES } from "@src/config/locales";
import { appLink } from "@src/utils/content/links";

export type AppLinkProps = Omit<LinkProps, "external"> & {};

export const AppLink = (props: AppLinkProps) => {
  const { to, ...rest } = props;
  const { locale } = useLocale();

  const localePath = Utils.findLocale(APP_SUPPORTED_LOCALES, locale) || APP_SUPPORTED_LOCALES[0]

  return (
    <Link
      to={`${appLink}${to}/${localePath}`}
      external
      {...rest}
    />
  );
}

export default AppLink;


